import { Component, OnDestroy, OnInit } from "@angular/core";
import { VentaService } from "../../core/services/venta.service";
import { ProductosService } from "../productos.service";
import { CalendarService } from "../ticket-sales/pages/date-selector/calendar/calendar.service";
import { ConfigurationService } from "src/app/core/config/configuration.service";
import * as moment from "moment";
import { AppConfiguration } from "src/app/app.configuration";
import { of, Subject, Observable } from "rxjs";
import { takeUntil } from "rxjs/internal/operators/takeUntil";
import { Producto } from "src/app/models/producto.model";
import { TranslateService } from "src/app/shared/translate.service";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { ButacasService } from "../espectaculos-butacas/mapa/servicios/butacas.service";
import { ButacasMultiplesService } from "../espectaculos-butacas/mapa/servicios/butacas.multiples.service";
import { EspectaculosService } from "../espectaculos-butacas/espectaculos.service"; 

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"],
})
export class CartComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  proyecto = this.config.getProyecto();
  compra$;
  entrada;
  nombreGrupo;
  mostrar: boolean;
  nombreUser;
  tieneUser;
  monthNames;
  diaNames;
  icon$;
  tienePrecioFinal = false;
  comision;
  IVAComision;
  descuento;
  espectaculo;
  grupoInternet;
  sesiones = [];
  viewTrash = true;
  viewUnselect$:  Observable<boolean>;

  constructor(
    private config: AppConfiguration,
    private calendarService: CalendarService,
    private ventaService: VentaService,
    private productoService: ProductosService,
    private translateService: TranslateService,
    private configurationService: ConfigurationService,
    private butacasService: ButacasService,
    private butacasMultiServices: ButacasMultiplesService,
    private espectaculosService: EspectaculosService,
  ) {
    this.monthNames = moment
      .months()
      .map((month) => month[0].toUpperCase() + month.substr(1));
    this.diaNames = moment
      .weekdays()
      .map((day) => day[0].toUpperCase() + day.substr(1));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
  
    this.productoService.nombreGrupo$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => (this.nombreGrupo = res));
    this.productoService.espectaculo$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => (this.espectaculo = res));
    this.productoService.datosInit$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => (this.grupoInternet = JSON.parse(res).gi));
       if (
          sessionStorage.getItem('reservaOriginal') &&
          this.butacasService.modificarValue() === true &&
          sessionStorage.getItem('admin')
          ) {
            const verifyIfExist = this.ventaService.compraValue.productos.find((item) => +item.posicionVenta === +this.espectaculosService.posicionVentaValue)
           if (!verifyIfExist) {
            const reservationOriginal = JSON.parse(sessionStorage.getItem('reservaOriginal'));
            reservationOriginal.compras.forEach(producto => {
              if (+producto.posicionVenta === +this.espectaculosService.posicionVentaValue) {
                this.ventaService.sendProductoModificar(producto);
              }
            });
           }
        }

    this.ventaService.compra$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((compra) => {
        this.entrada = compra.productos;
        if(this.entrada && this.entrada.length > 0) {
          const existButaca = this.entrada.find(item => item.producto?.butacas?.length > 0);
          if(existButaca && !this.viewTrash) {
            this.butacasMultiServices.setSiButacasValue(true);
          }      
        }        
        this.compra$ = compra;
        this.descuento = this.ventaService.compraValue.calcularDescuento();
        this.comision = this.ventaService.compraValue.calcularComision();
        this.IVAComision = this.ventaService.compraValue.calcularIVAComision();
        this.tienePrecioFinal =
          (this.comision && this.comision != 0) ||
          (this.descuento && this.descuento != 0)
            ? true
            : false;
      });
    this.cargarUsuario();
    this.productoService.showHours$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((hours) => {
        if (hours && hours.length > 0) {
          this.sesiones = [...hours];
        }
      });
  }

  private findDateSesion = () => {
    let dateSelect: string;
    let hourSelect: string;
    if (this.entrada && this.entrada.length > 0) {
      if (this.entrada[0].producto.butacas) {
        if (this.entrada[0].producto.butacas[0]) {
          dateSelect = this.entrada[0].producto.butacas[0].FechaSesion;
          hourSelect = this.entrada[0].producto.butacas[0].HoraSesion;
        }
      }
    } else if (this.sesiones.length > 0){
      //manieva si tenes una session seleccionada
      //no pisamos la fecha seleccionada con la fecha de butaca
    } else {
      this.butacasService.findDateSession();
      dateSelect = this.butacasService.realDate;
      hourSelect = this.butacasService.realHour;
    }
    if (dateSelect && hourSelect) {
      const dateObject = moment(dateSelect, "YYYY/MM/DD");
      const hourObject = moment(hourSelect, "HH:mm");
      this.ventaService.setHoraSeleccionadaValue(hourObject);
      this.ventaService.setFechaSeleccionadaValue(dateObject);
    }
    this.viewTrash = this.butacasMultiServices.checkMultipleValue ? false : true;
    this.viewUnselect$ = this.butacasMultiServices.siButacas$;
  };
  
  deleteButacaAsync(butaca) {
    let butacaselect;
    for (let i = 0; i < this.butacasService.butacasConPrecio.length; i++) {
      if (this.butacasService.butacasConPrecio[i].PKIDRB === butaca.idRB) {
        butacaselect = this.butacasService.butacasConPrecio[i];
        break;
      }
    }
    this.butacasService.desmarcarButaca(butacaselect);
  }
  
  deleteTipoEntradaAsync(posicionVenta) {
    for (let i = 0; i < this.butacasService.butacasConPrecio.length; i++) {
      if (
        this.butacasService.butacasConPrecio[i].PKIDRB === posicionVenta[0].idRB
      ) {
        this.butacasService.desmarcarButaca(
          this.butacasService.butacasConPrecio[i]
        );
        i = 0;
      }
    }
  }

  getCantidadPorProducto(producto: Producto): number {
    if (this.ventaService.compraValue) {
      return this.ventaService.getCantidadPorProducto(producto);
    }
    return 0;
  }

  compraTieneSesion() {
    if (!this.ventaService.compraValue.productos[0]) {
      return false;
    }
    let encontrado = true;

    this.ventaService.compraValue.productos.forEach((element) => {
      if (element.sesionesSeleccionadas) {
        encontrado = true;
      }
    });
    return encontrado;
  }

  diaReserva() {
    if (!this.ventaService.compraValue.productos[0]) {
      return null;
    }
    return this.diaReserva1();
  }

  diaReserva1() {
    let datelangarr = localStorage.getItem("lastlang").split("-");
    let datelang = datelangarr[0];
    let dateSel = moment(this.calendarService.currentDate);
    let fecha = moment(this.calendarService.currentDate)
      .locale(this.calendarService.moment.locale(datelang))
      .format("LL");
    if (this.ventaService.fechaSeleccionadaValue) {
      dateSel = moment(this.ventaService.fechaSeleccionadaValue);
      fecha = moment(this.ventaService.fechaSeleccionadaValue)
        .locale(this.calendarService.moment.locale(datelang))
        .format("LL");
    }
    let encontrado = false;
    this.ventaService.compraValue.productos.forEach((element) => {
      if (!encontrado && element.sesionesSeleccionadas) {
        if (element.sesionesSeleccionadas.length > 0) {
          dateSel = moment(
            element.sesionesSeleccionadas[0].Fecha,
            "DD/MM/YYYY"
          );
          fecha = moment(element.sesionesSeleccionadas[0].Fecha, "DD/MM/YYYY")
            .locale(this.calendarService.moment.locale(datelang))
            .format("LL");
          encontrado = true;
        }
      }
    });

    this.calendarService.selectedMonth = this.monthToString(
      this.calendarService.currentDate.month()
    );
    this.calendarService.selectedYear = this.calendarService.currentDate
      .year()
      .toString();
    if (this.calendarService.moment.locale().startsWith("en")) {
      fecha = fecha.slice(0, -6);
    } else {
      fecha = fecha.slice(0, -8);
    }

    // manieva sino tiene al menos alguna hora de productosService
    // se toma la uqe llega en la reserva

      const exist = this.sesiones.find(
        (item) => item.diaSinHora === dateSel.format("DD/MM/YYYY")
      );
  
      if (exist) {
        this.ventaService.setFechaSeleccionadaValue(dateSel);
      } else {
        this.findDateSesion();
      }   

    return (
      moment(this.ventaService.fechaSeleccionadaValue, "YYYY/MM/DD")
        .locale(this.calendarService.moment.locale(datelang))
        .format("dddd") +
      ", " +
      moment(this.ventaService.fechaSeleccionadaValue, "YYYY/MM/DD")
        .locale(this.calendarService.moment.locale(datelang))
        .format("LL")
    );
  }

  /**
   * @deprecated
   * @returns Horarios
   */
  sesionesSeleccionadas() {
    if (this.entrada[0]) {
      const sesiones = this.entrada[0].sesionesSeleccionadas;
      const butacas = this.entrada[0].butacas;
      if (sesiones) {
        sesiones.sort((a, b) => a.IdRecinto.localeCompare(b.IdRecinto));
        const horarios = [];
        sesiones.forEach((sesion) => {
          horarios.push({
            IdRecinto: sesion.IdRecinto,
            recinto: sesion.NombreRecinto,
            fecha: sesion.Fecha,
            hora: sesion.TipoAforo === 'SD' ? sesion.HoraInicio + '-' + sesion.HoraFin + this.idiomaSesion(sesion.IdiomaSesion) : sesion.HoraInicio + this.idiomaSesion(sesion.IdiomaSesion),
          });
        });
        return horarios;
      } else if (butacas) {
        const horarios = [];
        butacas.forEach((sesion) => {
          horarios.push({
            IdRecinto: sesion.IdRecinto,
            recinto: sesion.NombreRecinto,
            fecha: sesion.Fecha,
            hora: sesion.HoraInicio + this.idiomaSesion(sesion.IdiomaSesion),
          });
        });
        return horarios;
      }
    }
  }

  fechasSeleccionadas() {
    if (this.entrada[0]) {
      const sesiones = this.entrada[0].producto.ListTarifasfechasVigencia;
      if (sesiones) {
        //sesiones.sort((a, b) => a.IdRecinto.localeCompare(b.IdRecinto));
        const horarios = [];
        sesiones.forEach((sesion) => {
          horarios.push({ fecha: this.formatDate(sesion.FechaInicioTarifa) });
        });
        return horarios;
      }
    }
  }

  formatDate(date) {
    let dateFormat = moment(date, "DD/MM/YYYY h:mm:ss");
    return dateFormat.format("DD/MM/YYYY");
  }

  idiomaSesion(idioma) {
    if (idioma)
      if (this.translateService.data[idioma]) {
        return ". " + this.translateService.data[idioma];
      } else {
        return ". " + idioma;
      }
    return "";
  }

  calcularPrecioTotal() {
    return this.ventaService.compraValue.calcularPrecioTotal();
  }

  monthToString(num: number) {
    return this.monthNames[num];
  }

  dayToString(num: number) {
    return this.diaNames[num];
  }

  cargarUsuario() {
    if (JSON.parse(sessionStorage.getItem("admin"))) {
      if (JSON.parse(sessionStorage.getItem("admin")).u) {
        this.ventaService.cliente$
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((user) => {
            if (user) {
              this.nombreUser = this.ventaService.clienteValue.NombreContacto; //NOMBRE CLIENTE
              this.productoService.setTieneUserValue(true);
            }
          });
        this.productoService.tieneUser$
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((tiene) => (this.tieneUser = tiene));
      }
    }
  }

  tieneVariasFechas() {
    let encontrado = false;
    let valor = true;
    this.ventaService.compraValue.productos.forEach((element) => {
      if (
        !encontrado &&
        element.sesionesSeleccionadas &&
        element.sesionesSeleccionadas.length > 0
      ) {
        encontrado = true;
        if (
          element.sesionesSeleccionadas.every(
            (sesion, i, sesiones) => sesion.Fecha === sesiones[0].Fecha
          )
        ) {
          valor = false;
        }
      }
    });
    return valor;
  }
  // loadIcon() is your custom function to load icon definition asynchronously.
  loadIcon() {
    return of(faUser);
  }
  getDivisasValue() {
    return this.configurationService.divisasValue;
  }

  getTieneDescuento() {
    return this.ventaService.compraValue.TieneDescuento();
  }

  getDesglosaComision() {
    return this.ventaService.compraValue.DesglosarComision();
  }

  // calcularPrecioFinal() {
  //   //return this.ventaService.getPreciofinal();
  //   return this.ventaService.compraValue.calcularPrecioTotal() + this.calcularComision() + this.calcularIVAComision() - this.calcularDescuento();    
  // }

    /**
   * @description Calculate the final price of the purchase
   * @return {number} total final price
   */
    calcularPrecioFinal(): number {
      // Ensure accurate calculation regardless of data types:
      const totalPrice = this.ventaService.compraValue.calcularPrecioTotal();
      const comission = this.calcularComision();
      const ivaComission = this.calcularIVAComision();
      const discount = this.calcularDescuento();
    
      const commissions =  (parseFloat(comission) + parseFloat(ivaComission));
      const totalPricePlusCommisions = (parseFloat(totalPrice) + commissions);
      // Apply discount for clarity:
      const totalFinal = totalPricePlusCommisions - parseFloat(discount);
    
      return totalFinal;
    }
    

  calcularComision() {
    return this.ventaService.compraValue.calcularComision();
  }

  calcularIVAComision() {
    return this.ventaService.compraValue.calcularIVAComision();
  }

  calcularDescuento() {
    return this.ventaService.compraValue.calcularDescuento();
  }

  getPrecioPorProducto(producto: Producto): number {
    if (this.ventaService.compraValue) {
      return this.ventaService.getPrecioPorProducto(producto);
    }
    return 0;
  }

  compraTieneEspectaculo() {
    return (
      this.productoService.espectaculoValue &&
      this.productoService.espectaculoValue.EspectaculosId
    );
  }

  tieneFechaSeleccionada() {
    return this.ventaService.fechaSeleccionadaValue;
  }

  diaEspect() {
    if (this.ventaService.fechaSeleccionadaValue) {
      if (this.sesiones.length > 1) {
        return this.diaReserva1();
      } else {
        let datelangarr = localStorage.getItem("lastlang").split("-");
        let datelang = datelangarr[0];
        this.findDateSesion();
        return (
          moment(this.ventaService.fechaSeleccionadaValue, "YYYY/MM/DD")
            .locale(this.calendarService.moment.locale(datelang))
            .format("dddd") +
          ", " +
          moment(this.ventaService.fechaSeleccionadaValue, "YYYY/MM/DD")
            .locale(this.calendarService.moment.locale(datelang))
            .format("LL")
        );
      }
    } else {
      return null;
    }
  }

  horaEspect() {
    if (this.butacasService.addMore) {
      return;
    }
    if (this.ventaService.horaSeleccionadaValue) {
      return this.ventaService.horaSeleccionadaValue.format("HH:mm");
    } else {
      return null;
    }
  }

  onImgError(event) {
    event.target.src =
      "assets/img/" + this.proyecto + "/catalog/default_catalog.png";
    //Do other stuff with the event.target
  }

  public desmarcarAll = () => {   
    if(this.butacasMultiServices.listDesmarcarValue.length > 0) { 
      let reserva = this.productoService.getPlataforma() === "4" ? true : false;
      this.butacasMultiServices.marcarDesmarcarButacasMultiple(this.butacasMultiServices.listDesmarcarValue, '0').subscribe((res) => {
        if(res && res.IdRB.length > 0) {   
          res.IdRB.forEach((id) => { 
          const butacaSel = this.butacasService.selectedSeats.find(element => +element.PKIDRB === id);
          const b = this.espectaculosService.existeButaca(butacaSel);
          this.espectaculosService.eliminarButacaDesdeMapa(b); 
          this.butacasService.selectedSeats =
            this.butacasService.selectedSeats.filter(
              (item) => item.PKIDRB !== +id
            );          
            const color = this.butacasService.getColorButaca(butacaSel, false, reserva);
            const butacaDom = document.querySelector(
              "circle[_PKIDRB='" + id + "']"
            );              
            butacaDom.removeAttribute("style");
            butacaDom.setAttribute(
              "style",
              `fill: ${color}`
            );
            butacaDom.classList.remove("marcada");           
          });
              
        }        
      });       
      this.butacasMultiServices.setlistDesmarcar([]);
      this.butacasMultiServices.arrMarcadas = [];
      this.butacasMultiServices.setCheckMultipleValue(false);
      this.butacasMultiServices.setDisabledMultiple(false);
      this.butacasMultiServices.setSiButacasValue(false);
      this.butacasService.butacasSeleccionadas = [];
      this.butacasMultiServices.setTotalButacas(0);
      this.butacasMultiServices.setTotalAcompana(0);
      this.butacasMultiServices.setTotalDiscapa(0);
      this.butacasMultiServices.setTotalGeneral(0);
      this.butacasMultiServices.setTotalProfe(0);
    }    
  }
  
}
