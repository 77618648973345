import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, exhaustMap, take, takeUntil } from 'rxjs/operators';
import { ProductosService } from 'src/app/business/productos.service';
import { VentaService } from 'src/app/core/services/venta.service';
import { Cliente } from 'src/app/models/cliente.model';
import { ModalService } from 'src/app/shared/modal.service';
import { PagerService } from 'src/app/shared/pager.service';
import { TranslateService } from 'src/app/shared/translate.service';
import { UserService } from 'src/app/user/user.service';
import { UtilsService } from '../../../utils.service';
import { GlobalService } from '../../../../shared/services/global/global.service';

@Component({
  selector: 'app-formulario-cliente',
  templateUrl: './formulario-cliente.component.html',
  styleUrls: ['./formulario-cliente.component.scss']
})
export class FormularioClienteComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  modelChanged: Subject<string> = new Subject<string>();
  isLoading = true;
  pager: any = {};
  pagedItems: any[];
  totalPages: number;
  datosForm: FormGroup;
  campos;
  abrir = [];
  clientes = [];
  campo;
  ilogueo = 0;
  paisSeleccionado; //Yaribel 20210531 Añadimos variable para obtener el Codigo del pais seleccionado
  user;
  @Input() paises: any;
  @Input() provincias: any;
  @Input() provinciaCliente: string;
  @Output() datosClienteForm = new EventEmitter<FormGroup>();  
  
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private translateService: TranslateService,
    private pagerService: PagerService,
    private ventaService: VentaService,
    private productosService: ProductosService,
    private modalService: ModalService,
    private util: UtilsService,
    private global: GlobalService,
  ) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.userService.currentUser$.subscribe(user =>{
       this.user = user;
       if(!user){
         if(this.datosForm){
         Object.keys((this.datosForm.get("items") as FormGroup).controls).forEach(control=>{
          (this.datosForm.get("items") as FormGroup).get(control).setValue("")
          })
         }
       }
      });

    this.ventaService.enlaceMenuCarrito$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(em => {
      if (em) {
        const enlaceMenu = this.productosService.enlacesMenuValue.find(e => e.pkid === em);
        this.ilogueo = enlaceMenu.iLogueo;
        this.loginRegister();
        this.ventaService.userReserva$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.cargarCamposDinamicos(enlaceMenu));
      }
    });
  }

  loginRegister(){
    if( +this.ilogueo > 0 ){           
      if(this.user==null && !this.userService.userAdminValue){
        this.modalService.open("LoginAndRegister");
      }
    }
  }

  get p() {
    return this.datosForm.controls;
  }

  getControls(datosForm: FormGroup) {
    return (<FormArray>datosForm.controls.items);
  }

  cargarCamposDinamicos(enlaceMenu) {
    if (JSON.parse(sessionStorage.getItem('cliente'))) {
      this.ventaService.setCliente(JSON.parse(sessionStorage.getItem('cliente')));
    }
    this.campos = JSON.parse(enlaceMenu.CaracteristicasAdicionales).CaracteristicasAdicionales.DatosCompra;   
    if (this.campos.find(campo => campo.busqueda)) {
      this.buscador();
    }
    this.datosForm = this.formBuilder.group({
      items: this.createFormItems(this.campos)
    });
    const a = this.getControls(this.datosForm);
    const controls: any = a.controls;
    if (controls.tipoDocumento) {
      controls.tipoDocumento.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(checked => {
        if (checked) {
          controls.documento.setValidators([Validators.required, Validators.pattern(this.documentoValidar(controls.tipoDocumento.value)),this.ValidarDocNieDni('tipoDocumento')]);
        }
      });
    }
 
    this.datosClienteForm.emit(this.datosForm);
    this.datosForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.datosClienteForm.emit(this.datosForm));
  }

  documentoValidar(tipoDocumento: string) {
    const control = tipoDocumento;
    const nifReg = /^(\d{8})([A-Z]|[a-z])$/;
    const nieReg = /^[XYZ][0-9]{7}[A-Z]$/;
    if (control === 'NIE') {
      return nieReg;
    }
    if (control === 'DNI') {
      return nifReg;
    }
    if (control === 'Otro') {
      return null;
    }
  }

  ValidarDocumento(tipoDocumento: string,numeroDocumento : string) {
    let numValido = true;
    const control = tipoDocumento;
    if (control === 'NIE') {
      numValido = this.global.VerificarNIE(numeroDocumento)
    }
    if (control === 'DNI') {
      numValido = this.global.VerificarDNI(numeroDocumento)
    }
    if (control === 'CIF') {
      numValido = this.global.VerificarCIF(numeroDocumento)
    }
    return numValido;
  }


  repetirValidar(otherControlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const a = control.root['controls'];
      let otherControl: AbstractControl;
      if (a) {
        otherControl = a.items.get(otherControlName);
      }
      if (otherControl) {
        const subscription: Subscription = otherControl
          .valueChanges
          .subscribe(() => {
            control.updateValueAndValidity();
            subscription.unsubscribe();
          });
      }

      let control1 = control? (control.value == undefined? "":control.value):"";;
       let control2 = otherControl ? (otherControl.value == undefined? "":otherControl.value):"";
        return (control && control1.toLowerCase() !== control2.toLowerCase()) ? { match: true } : null;
    };
  }


  ValidarDocNieDni(otherControlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const a = control.root['controls'];
      let otherControl: AbstractControl;
      if (a) {
        otherControl = a.items.get(otherControlName);
      }
      if (otherControl) {
        const subscription: Subscription = otherControl
          .valueChanges
          .subscribe(() => {
            control.updateValueAndValidity();
            subscription.unsubscribe();
          });
      }

      let documento = control ? (control.value == undefined ? "" : control.value):"";
      let tipoDocumento = otherControl ? (otherControl.value == undefined? "":otherControl.value):"";     
      return (!this.ValidarDocumento(tipoDocumento, documento)) ? { match: true } : null;
    };
  }

  noAfiliado(campo) {
    let afil = true;
    if (campo) {
      if (campo=="" || campo =="0") {
        afil = true;
      }else {
        afil = false;
      }
      return afil;
    } else {
      return true;
    }
  }

  createFormItems(campos): FormGroup {
    const cliente = this.ventaService.clienteValue;
    this.user = JSON.parse(sessionStorage.getItem('currentUser'));
    if(cliente?.TipoDocumento) { 
      cliente.TipoDocumento = cliente.TipoDocumento.includes('DNI') ? 'DNI' : cliente.TipoDocumento;
    }   
    if(cliente && !cliente.Provincia) {
      cliente.Provincia = this.provinciaCliente;
      this.ventaService.setCliente(cliente);
    }
    const group = {};
    campos.forEach((campo: any) => { 
      if (campo.visible) {
        switch (campo.name) {          
          case 'Nombrecontacto':            
            return group[campo.name] = new FormControl(              
              cliente && cliente.NombreCliente!="" ? this.noAfiliado(cliente.Afiliado) ? cliente.NombreCliente: "" : this.user ? this.noAfiliado(this.user.Afiliado) ? this.user.NombreClienteOriginal :"" :"",
              campo.required ? [Validators.required, Validators.pattern('^[A-zÀ-ÿ\.\\s]*$')] : Validators.pattern('^[A-zÀ-ÿ\.\\s]*$'));
          case 'nombre':
            return group[campo.name] = new FormControl(
              cliente && cliente.NombreCliente!="" ? this.noAfiliado(cliente.Afiliado) ? cliente.NombreCliente : "" : this.user ? this.noAfiliado(cliente.Afiliado) ? this.user.NombreClienteOriginal :"" :"",
              campo.required ? [Validators.required, Validators.pattern('^[A-zÀ-ÿ\\s]*$')] : Validators.pattern('^[A-zÀ-ÿ\\s]*$'));
          case 'apellidos':
            return group[campo.name] = new FormControl(
              cliente && cliente.Apellido1!="" ? this.noAfiliado(cliente.Afiliado) ? cliente.Apellido1 : "" :  this.user ? this.noAfiliado(cliente.Afiliado) ? this.user.PrimerApellidoCliente: "": "",
              campo.required ? [Validators.required, Validators.pattern('^[A-zÀ-ÿ\\s]*$')] : Validators.pattern('^[A-zÀ-ÿ\\s]*$'));
          case 'Apellido2Contacto':
            return group[campo.name] = new FormControl(
              cliente && cliente.Apellido2!="" ? this.noAfiliado(cliente.Afiliado) ? cliente.Apellido2 : "" :  this.user ? this.noAfiliado(cliente.Afiliado) ? this.user.SegundoApellidoCliente:"" :"",
              campo.required ? [Validators.required, Validators.pattern('^[A-zÀ-ÿ\\s]*$')] : Validators.pattern('^[A-zÀ-ÿ\\s]*$'));
          case 'emailconfirmacion':
            return group[campo.name] = new FormControl(
              cliente && cliente.EmailConfirmacion ? this.noAfiliado(cliente.Afiliado) ? cliente.EmailConfirmacion : "" : cliente && cliente.Email ? cliente.Email : "",
              campo.required ? [Validators.required, Validators.pattern('^[A-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[A-z0-9!#$%&\'*+/=?^_\`{|}~-]+)*@(?:[A-z0-9](?:[A-z0-9-]*[A-z0-9])?\\.)+[A-z0-9](?:[A-z0-9-]*[A-z0-9])?$'), this.repetirValidar('email')] : [Validators.pattern('^[A-z0-9!#$%&\'*+/=?^_\`{|}~-]+(?:\\.[A-z0-9!#$%&\'*+/=?^_\`{|}~-]+)*@(?:[A-z0-9](?:[A-z0-9-]*[A-z0-9])?\\.)+[A-z0-9](?:[A-z0-9-]*[A-z0-9])?$'), this.repetirValidar('email')]);          
          case 'email':
            return group[campo.name] = new FormControl(
              cliente && cliente.Email!="" ? this.noAfiliado(cliente.Afiliado) ? cliente.Email : "" :  this.user ? this.noAfiliado(cliente.Afiliado) ? this.user.EMail : "" :"",
              campo.required ? [Validators.required, Validators.pattern('^[A-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[A-z0-9!#$%&\'*+/=?^_\`{|}~-]+)*@(?:[A-z0-9](?:[A-z0-9-]*[A-z0-9])?\\.)+[A-z0-9](?:[A-z0-9-]*[A-z0-9])?$')] : Validators.pattern('^[A-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[A-z0-9!#$%&\'*+/=?^_\`{|}~-]+)*@(?:[A-z0-9](?:[A-z0-9-]*[A-z0-9])?\\.)+[A-z0-9](?:[A-z0-9-]*[A-z0-9])?$'));
          case 'localidad':
            return group[campo.name] = new FormControl(
              cliente && cliente.Ciudad!="" ? this.noAfiliado(cliente.Afiliado) ? cliente.Ciudad : "" : this.user ? this.noAfiliado(cliente.Afiliado) ? this.user.Localidad :"" :"",
              campo.required ? Validators.required : Validators.nullValidator);
          case 'provincia':
            return group[campo.name] = new FormControl(
              cliente && cliente.Provincia!="" ? this.noAfiliado(cliente.Afiliado) ? cliente.Provincia : "" : this.user ? this.noAfiliado(cliente.Afiliado) ? this.user.Provincia :"" :"",
              campo.required ? Validators.required : Validators.nullValidator);    
          case 'nombrecentro':
            return group[campo.name] = new FormControl(
              cliente && cliente.NombreContacto!="" ? this.noAfiliado(cliente.Afiliado) ? cliente.NombreContacto : "" : '',
              campo.required ? Validators.required : Validators.nullValidator);
          case 'nombreresponsable':
            return group[campo.name] = new FormControl(
              cliente && cliente.NombreResponsable!="" ? this.noAfiliado(cliente.Afiliado) ? cliente.NombreResponsable : "" : '',
              campo.required ? Validators.required : Validators.nullValidator);
          case 'CIFoDNI':
            return group[campo.name] = new FormControl(
              cliente && cliente.Documento!=""  ? this.noAfiliado(cliente.Afiliado) ? cliente.Documento : "" : '',
              campo.required ? [Validators.required,this.ValidarDocNieDni('tipoDocumento')] : [Validators.nullValidator,this.ValidarDocNieDni('tipoDocumento')]);
          case 'documento':
            return group[campo.name] = new FormControl(
              cliente && cliente.Documento!="" ? this.noAfiliado(cliente.Afiliado) ? cliente.Documento : "" : '',
              campo.required ? [Validators.required,this.ValidarDocNieDni('tipoDocumento')] : [Validators.nullValidator,this.ValidarDocNieDni('tipoDocumento')]);              
          case 'codigoPostal':
            return group[campo.name] = new FormControl(
              cliente && cliente.CodigoPostal!="" ? this.noAfiliado(cliente.Afiliado) ? cliente.CodigoPostal : "" :  this.user ? this.noAfiliado(cliente.Afiliado) ? this.user.CP : "" :"", 
              campo.required ? Validators.required : Validators.nullValidator);
          case 'telefono':
            return group[campo.name] = new FormControl(
              cliente && cliente.Telefono!="" ? this.noAfiliado(cliente.Afiliado) ? cliente.Telefono : "" : '',
              campo.required ? Validators.required : Validators.nullValidator);
          case 'telefonomovil':
            return group[campo.name] = new FormControl(
              cliente && cliente.Telefono2!="" ? this.noAfiliado(cliente.Afiliado) ? cliente.Telefono2 : "" : '',
              campo.required ? Validators.required : Validators.nullValidator);
          case 'direccion':
            return group[campo.name] = new FormControl(
              cliente && cliente.Direccion!="" ? this.noAfiliado(cliente.Afiliado) ? cliente.Direccion : "" : '',
              campo.required ? Validators.required : Validators.nullValidator);
          case 'ciudad':
            return group[campo.name] = new FormControl(
              cliente && cliente.Ciudad!="" ? this.noAfiliado(cliente.Afiliado) ? cliente.Ciudad : "" : '',
              campo.required ? Validators.required : Validators.nullValidator);
          case 'tipoDocumento':
            return group[campo.name] = new FormControl(
              cliente && cliente.TipoDocumento!="" ? this.noAfiliado(cliente.Afiliado) ? cliente.TipoDocumento : "" : '',
              campo.required ? Validators.required : Validators.nullValidator);
          case 'notas':
            return group[campo.name] = new FormControl(
              cliente && cliente.Notas!=""  ? this.noAfiliado(cliente.Afiliado) ? cliente.Notas : "" : '',
              campo.required ? Validators.required : Validators.nullValidator);
          case 'notasinternas':
            return group[campo.name] = new FormControl(
              cliente && cliente.NotasInternas!="" ? cliente.NotasInternas ? this.noAfiliado(cliente.Afiliado) ? cliente.NotasInternas : "" : cliente.ObsAdvertencia  ? this.noAfiliado(cliente.Afiliado) ? cliente.ObsAdvertencia : "" : "" :"",
              campo.required ? Validators.required : Validators.nullValidator);
          case 'tipocentro':
            return group[campo.name] = new FormControl(
              cliente && cliente.TipoCentro!="" ? this.noAfiliado(cliente.Afiliado) ? cliente.TipoCentro  : "" : '',
              campo.required ? Validators.required : Validators.nullValidator);
          case 'curso':
            return group[campo.name] = new FormControl(
              cliente && cliente.Curso!=""   ? this.noAfiliado(cliente.Afiliado) ? cliente.Curso : "" : '',
              campo.required ? Validators.required : Validators.nullValidator);
          case 'pais':
            group[campo.name] = new FormControl(
              cliente && cliente.CodigoPais!="" ? this.noAfiliado(cliente.Afiliado) ? cliente.CodigoPais : "" : '',
              campo.required ? Validators.required : Validators.nullValidator);

            group['codigoPais'] = new FormControl(
              cliente && cliente.CodigoPais!="" ? this.noAfiliado(cliente.Afiliado) ? cliente.CodigoPais : "" : '',
              campo.required ? Validators.required : Validators.nullValidator);
              return;
          default:
            return group[campo.name] = new FormControl(
              '',
              campo.required ? Validators.required : Validators.nullValidator);
        }
      }
    }
    );
    const apiFormGroup = new FormGroup(group);
    this.isLoading = false;
    return apiFormGroup;
  }

  campoTipo(chTipoControl: string) {
    switch (chTipoControl) {
      case 'radiobutton': return 'dropDown';
      case 'select': return 'dropDown';
      case 'dropDown': return 'dropDown';
      case 'input': return 'text';
      default: return 'unknown';
    }
  }

  abrirBuscador(campo) {
    this.abrir = [];
    this.abrir.push({ campo, abierto: true });
    this.clientes = [];
    this.setPage(1);
  }

  estaAbierto(campo) {
    let abierto = false;
    if (this.abrir.length) {
      const campoAbierto = this.abrir.find(c => campo === c.campo);
      if (campoAbierto) {
        abierto = true;
      }
      return abierto;
    } else {
      return false;
    }
  }

  textoBuscar(texto, campo) {
    this.modelChanged.next(texto);
    this.campo = campo;
  }

  buscador() {
    this.modelChanged
      //.pipe(filter((res: any) => res.length > 2))
      .pipe(debounceTime(500))
      .pipe(exhaustMap(input => this.userService.getClientes(input, this.campo))).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
        if (res) {
          if (res.length) {
            this.clientes = res;
            this.totalPages = Math.ceil(this.clientes.length / 5);
            this.setPage(1);
          } else {
            this.clientes = [];
            this.setPage(1);
          }
        } else {
          this.clientes = [];
          this.setPage(1);
        }
      });
  }

  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.clientes.length, page);
    this.pagedItems = this.clientes.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.pager.pages = this.pager.pages.filter(num => num <= this.pager.totalPages);
    if (page > 6) {
      while (this.pager.pages.length < 6) {
        this.pager.pages.unshift(this.pager.pages[0] - 1);
      }
    }
  }

  anterior() {
    this.setPage(this.pager.currentPage - 1);
  }

  siguiente() {
    this.setPage(this.pager.currentPage + 1);
  }

  seleccionarCliente(cliente) {
    this.ventaService.mapCliente(cliente);
    const clienteModel = this.ventaService.clienteValue;
    const a = this.getControls(this.datosForm);
    const controls: any = a.controls;
    controls.nombrecentro.patchValue(clienteModel.NombreContacto);
    controls.nombreresponsable.patchValue(clienteModel.NombreResponsable);
    controls.email.patchValue(clienteModel.Email);
    controls.ciudad.patchValue(clienteModel.Ciudad);
    controls.direccion.patchValue(clienteModel.Direccion);
    controls.codigoPostal.patchValue(clienteModel.CodigoPostal);
    controls.telefono.patchValue(clienteModel.Telefono);
    controls.CIFoDNI.patchValue(clienteModel.Documento);
    controls.notasinternas.patchValue(clienteModel.NotasInternas);
    controls.pais.patchValue(clienteModel.Pais);
    controls.codigoPais.patchValue(clienteModel.CodigoPais);
    if(controls.tipocentro){
      controls.tipocentro.patchValue(clienteModel.TipoCentro);
    }
    controls.apellidos.patchValue(clienteModel.PrimerApellidoCliente);
    this.focusOut();
    this.abrir = [];
  }

  focusOut() {
    const clienteGuardado = this.ventaService.clienteValue;
    const cliente = { ...this.datosForm.value.items };
    let nombre;
    let apellidos;
    let apellido1;
    let apellido2;
    let user;
    let password;
    let afiliado;
    let tipopagonombre;

    if (clienteGuardado) { 
      nombre = cliente.Nombrecontacto ? cliente.Nombrecontacto : clienteGuardado.NombreClienteAdicional? clienteGuardado.NombreClienteAdicional: clienteGuardado.NombreCliente;
      apellidos = cliente.apellidos ? cliente.apellidos : clienteGuardado.Apellidos;
      apellido1 = cliente.apellidos ? cliente.apellidos : clienteGuardado.Apellido1;
      apellido2 = cliente.Apellido2Contacto ? cliente.Apellido2Contacto : clienteGuardado.Apellido2;
      user =  clienteGuardado.User ? clienteGuardado.User : null;
      password = clienteGuardado.Pass ? clienteGuardado.Pass : null;
      afiliado=clienteGuardado.Afiliado?clienteGuardado.Afiliado:'';
      tipopagonombre=clienteGuardado.TipoPagoNombre?clienteGuardado.TipoPagoNombre:'';

    } else {
      nombre = cliente.Nombrecontacto ? cliente.Nombrecontacto : '';
      apellidos = cliente.apellidos ? cliente.apellidos : '';
      apellido1 = cliente.apellidos ? cliente.apellidos : '';
      apellido2 = cliente.Apellido2Contacto ? cliente.Apellido2Contacto : '';
      afiliado= cliente.Afiliado ? cliente.Afiliado : '';
      tipopagonombre= cliente.TipoPagoNombre ? cliente.TipoPagoNombre : '';
    }
    
    const newClient: Cliente = {
      User: user,
      Pass: password,
      Documento: cliente.CIFoDNI ? cliente.CIFoDNI : cliente.documento,
      Ciudad: cliente.ciudad,
      CodigoPostal: cliente.codigoPostal,
      Direccion: cliente.direccion,
      Email: cliente.email,
      EmailConfirmacion: cliente.emailconfirmacion,
      NombreCliente: cliente.nombre ? cliente.nombre : nombre,
      NombreContacto: cliente.nombrecentro, 
      NombreResponsable: cliente.nombreresponsable,
      // manieva 11520
      // cuando el atributo de nombre no esta disponible usamos el de user
      // sino este valor se pierde y es necesario para otras flujos
      Nombre: cliente.nombre ?? user,
      Notas: cliente.notas,
      NotasInternas: cliente.notasinternas ? cliente.notasinternas : "",
      Telefono: cliente.telefono,
      Apellidos: apellidos,
      Apellido1: apellido1,
      Apellido2: apellido2,
      Pais: cliente.pais,
      CodigoPais: cliente.codigoPais,
      TipoDocumento: cliente.tipoDocumento,
      Telefono2: cliente.telefonomovil,
      TipoCentro: cliente.tipocentro,
      Curso: cliente.curso,
      Provincia: cliente.provincia,
      Publicidad: undefined,
      Privacidad: undefined,
      Afiliado: afiliado,
      TipoPagoNombre: tipopagonombre,
    };
    this.ventaService.setCliente(newClient);
    //sessionStorage.setItem('cliente', JSON.stringify(newClient));
  }

  nombreCampo(nombreCampo) {
    return this.util.getPlaceHolder(nombreCampo);
  }
//<<Yaribel 20210531 añadimos funcion para cargar el nombre del pais cuando se selecciona un nuevo pais
  SeleccionaPais(){
    if (this.datosForm.get('items').get('pais')) {
      this.datosForm.get('items').get('pais').setValue(        
        this.paises.find(e=> e.Id == this.paisSeleccionado) ? this.paises.find(e=> e.Id == this.paisSeleccionado).Nombre : ''
        );
            this.datosForm.get('items').get('provincia').setValue('');
    }
  }
  //Yaribel 20210531 añadimos funcion para cargar el nombre del pais cuando se selecciona un nuevo pais>>
}



