import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VentaService } from 'src/app/core/services/venta.service';
import { TranslateService } from 'src/app/shared/translate.service';
import * as _ from 'lodash';
import { AppConfiguration } from 'src/app/app.configuration';
import { ConfigurationService } from 'src/app/core/config/configuration.service';
import { HostListener } from '@angular/core';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ModalService } from 'src/app/shared/modal.service';
import { UserService } from 'src/app/user/user.service';
import { InitService } from 'src/app/core/init/init.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { DialogoPopUp } from 'src/app/shared/components/alert/dialog/popup.service';
import { ButacasService } from 'src/app/business/espectaculos-butacas/mapa/servicios/butacas.service';
import { Carrito } from 'src/app/models/carrito.model';
import { take } from 'rxjs/operators';
import { TimerService } from 'src/app/core/services/timer.service';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class HeaderComponent implements OnInit {
  nombreCliente;
  proyecto = this.config.getProyecto();
  listaCarrito;
  size_width: any;
  size_height: any;
  infoCarritoTipo: boolean = true;
  hidden;
  fromAdmon: Subscription;
  fromAdm = false;
  numCar$: Observable<number>

  constructor(
    private router: Router,
    private ventaService: VentaService,
    private translate: TranslateService,
    private userService: UserService,
    private configurationService: ConfigurationService,
    private config: AppConfiguration,
    public dialog: MatDialog,
    public _cdr: ChangeDetectorRef,
    private modalService: ModalService,
    private initService:InitService,
    private translator: TranslateService,
    private butacasService: ButacasService,
    private timerService: TimerService
    ) {
  }

  ngOnInit() {
    if ((JSON.parse(sessionStorage.getItem('admin')))) {
      if ((JSON.parse(sessionStorage.getItem('admin'))).u) {
        this.ventaService.cliente$.subscribe(user => {
          if (user) {
            this.nombreCliente = user.NombreContacto;
          }
        });
      }
    }

    this.fromAdmon = this.initService.fromAdmon$.subscribe(adm => this.fromAdm = adm);  

    this.ventaService.setNumCarrito();
    this.numCar$ = this.ventaService.numCarrito$;
        
    this.ventaService.carrito$.subscribe(compras => {
      this.listaCarrito = this.ventaService.ordenarCarrito();
    });

    this.inicioSaberTamañoPantalla();

    this.userService.setlang.subscribe(l => {

     let _that = this;
     setTimeout(function() {_that._cdr.detectChanges()},4000);
    });

    this.userService.logged.subscribe(l => {

      let _that = this;
      setTimeout(function() {_that._cdr.detectChanges()},4000);
     });
    
    let _that = this;
    setTimeout(function() {_that._cdr.detectChanges()},4000);
  }

  navigateTop(route) {
    if (this.ventaService.modificandoReservaValue) {
      const dialogRef = this.dialog.open(DialogoPopUp, {
        width: "250px",
        data: {
          titulo: this.translator.data["TERMINAR_MODIFICACION"],
          texto: this.translator.data["Confirmar_terminar_modificacion"],
          eleccion: "Patatas",
          ok: this.translator.data["CONFIRMAR"],
          ko: this.translator.data["CANCELAR"],
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === "SI") {
          this.butacasService
          .DesmarcarTodoCarrito()
          .pipe(take(1))
          .subscribe((res) => {
            this.butacasService.MarcarTodoCarrito().pipe(take(1)).subscribe();
          });
          this.timerService.reset();
          this.butacasService.resetValues();
          this.router.navigate([route]);
        }
      });
    } else {
      this.router.navigate([route]);
    }
  }

  formatDate(date){
    let dateFormat = moment(date,"DD/MM/YYYY h:mm:ss")
    return dateFormat.format("DD/MM/YYYY")
  }
  inicioSaberTamañoPantalla() {
    this.size_width = window.innerWidth;
    this.size_height = window.innerHeight;
    this.paraTelefonoOMonitorInfoCarrito();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.size_width = event.target.innerWidth;
    this.size_height = event.target.innerHeight;
    this.paraTelefonoOMonitorInfoCarrito();
  }

  paraTelefonoOMonitorInfoCarrito() {
    if (this.size_width > 900) {
      this.infoCarritoTipo = true;
    } else {
      this.infoCarritoTipo = false;
    }
  }

  precioTotalGrupo(grupo) {
    return this.ventaService.carritoValue.calcularPrecioTotalGrupoCarrito(grupo);
  }

  getPrecioPorProducto(compra) {
    return this.ventaService.carritoValue.getPrecioPorProducto(compra);
  }

  precioTotal() {
    return this.ventaService.carritoValue.calcularPrecioTotal();
  }

  idiomaSesion(idioma) {
    switch (idioma) {
      case 'ca':
        return '. Català';
      case 'en-GB':
        return '. English';
      case 'es-ES':
        return '. Castellano';
      default:
        return '. ' + idioma;
    }
  } 

  irCarrito() {
    //Open dialog component window
    this.hidden = true;
    this.router.navigate(['/tienda/resumen']);

  }

  mouseEnter() {
    this.hidden = false;
  }

  getRouterStartWith(ruta){
    return this.router.url.startsWith(ruta);
  }

  getRoute(ruta){
    return this.router.url === ruta;
  }

  getDivisasValue(){
    return this.configurationService.divisasValue;
  }

}

