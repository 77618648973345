import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from 'rxjs/operators';

import { ProductosService } from "src/app/business/productos.service";
import { ConfigurationService } from "src/app/core/config/configuration.service";
import { environment } from "src/environments/environment";
import { TranslateService } from "src/app/shared/translate.service";
import { 
  TarifasCantidad, 
  ReqButacasMultiple, 
  ResButacasMultiple, 
  ReqButacaInfo, 
  ResButacasInfo,
  Marcado,
  ReqMarcarDesmarcar,
  ResMarcarDesmarcar, 
} from "../../modelos/butaca.model";

@Injectable({
  providedIn: "root",
})
export class ButacasMultiplesService { 

  private checkMultipleSubject: BehaviorSubject<boolean>;
  public checkMultiple$: Observable<boolean>; 
  private totalButacasSubject: BehaviorSubject<number>;
  public totalButacas$: Observable<number>; 
  private totalGeneralSubject: BehaviorSubject<number>;
  public totalGeneral$: Observable<number>; 
  private totalProfeSubject: BehaviorSubject<number>;
  public totalProfe$: Observable<number>; 
  private totalDiscapaSubject: BehaviorSubject<number>;
  public totalDiscapa$: Observable<number>; 
  private totalAcompanaSubject: BehaviorSubject<number>;
  public totalAcompana$: Observable<number>; 
  private butacaMarcadaSubject: BehaviorSubject<any>;
  public butacaMarcada: Observable<any>;
  private siButacasSubject: BehaviorSubject<boolean>;
  public siButacas$: Observable<boolean>; 
  private listDesmarcarSubject: BehaviorSubject<Marcado[]>;
  public listDesmarcar$: Observable<Marcado[]>;
  private disabledMultipleSubject: BehaviorSubject<boolean>;
  public disabledMultiple$: Observable<boolean>; 
  public arrMarcadas: Marcado[] = [];


  constructor(
    private http: HttpClient,
    private productosService: ProductosService,
    private configurationService: ConfigurationService,
    private translate: TranslateService,
  ) {
    this.checkMultipleSubject = new BehaviorSubject(false);
    this.checkMultiple$ = this.checkMultipleSubject.asObservable();
    this.totalButacasSubject = new BehaviorSubject(0);
    this.totalButacas$ = this.totalButacasSubject.asObservable();
    this.totalAcompanaSubject = new BehaviorSubject(0);
    this.totalAcompana$ = this.totalButacasSubject.asObservable();
    this.totalDiscapaSubject = new BehaviorSubject(0);
    this.totalDiscapa$ = this.totalButacasSubject.asObservable();
    this.totalGeneralSubject = new BehaviorSubject(0);
    this.totalGeneral$ = this.totalButacasSubject.asObservable();
    this.totalProfeSubject = new BehaviorSubject(0);
    this.totalProfe$ = this.totalButacasSubject.asObservable();
    this.butacaMarcadaSubject = new BehaviorSubject(undefined);
    this.butacaMarcada = this.butacaMarcadaSubject.asObservable();
    this.siButacasSubject = new BehaviorSubject(false);
    this.siButacas$ = this.siButacasSubject.asObservable();
    this.listDesmarcarSubject= new BehaviorSubject([]);
    this.listDesmarcar$ = this.listDesmarcarSubject.asObservable();
    this.disabledMultipleSubject = new BehaviorSubject(false);
    this.disabledMultiple$ = this.disabledMultipleSubject.asObservable();
  }  

  
  public setCheckMultipleValue = (status: boolean) => {
    this.checkMultipleSubject.next(status);
  } 

  public get checkMultipleValue() {
    return this.checkMultipleSubject.value;
  }

  public get siButacasValue() {
    return this.siButacasSubject.value;
  }

  public setSiButacasValue = (status: boolean) => {
    this.siButacasSubject.next(status);
  } 

  public get butacaMarcadaValue() {
    return this.butacaMarcadaSubject.value;
  }

  public setButacaMarcadaValue(butaca) {
    this.butacaMarcadaSubject.next(butaca);
  }

  public get totalButacasValue() {
    return this.totalButacasSubject.value;
  }

  public setTotalButacas = (num: number) => {
    this.totalButacasSubject.next(num);
  }

  public get totalGeneralValue() {
    return this.totalGeneralSubject.value;
  }

  public setTotalGeneral = (num: number) => {
    this.totalGeneralSubject.next(num);
  }

  public get totalProfeValue() {
    return this.totalProfeSubject.value;
  }

  public setTotalProfe = (num: number) => {
    this.totalProfeSubject.next(num);
  }

  public get totalDiscapaValue() {
    return this.totalDiscapaSubject.value;
  }

  public setTotalDiscapa = (num: number) => {
    this.totalDiscapaSubject.next(num);
  }

  public get totalAcompanaValue() {
    return this.totalAcompanaSubject.value;
  }

  public setTotalAcompana = (num: number) => {
    this.totalAcompanaSubject.next(num);
  }

  public get listDesmarcarValue() {
    return this.listDesmarcarSubject.value;
  }

  public setlistDesmarcar = (arr: Marcado[]) => {
    this.listDesmarcarSubject.next(arr);
  }

  public get disabledMultipleValue() {
    return this.disabledMultipleSubject.value;
  }

  public setDisabledMultiple = (status: boolean) => {
    this.disabledMultipleSubject.next(status);
  }

  getConfig(key: any) {
    return environment[key];
  }

  getAPIUrl() {
    return this.getConfig("API_URL") + "api/";
  }

  public getButacasMultiples = (marcada: number, lista: TarifasCantidad[]) => { 
    const body: ReqButacasMultiple = {
      Plataforma: this.productosService.getPlataforma(),
      SesionInternetId: this.configurationService.configurationValue.currentSession.sessionId,
      IdRBMarcada: marcada,
      ValidarTarifa: 1,
      ListaTarifasCantidad: lista,
    }
    return this.http.post<ResButacasMultiple>(`${this.getAPIUrl()}MarcarButacasMultiple`, body).pipe(map((res: ResButacasMultiple) => {
      if (res.DatosResult.ListaTarifas && res.DatosResult.ListaTarifas.length > 0) {
        return res.DatosResult.ListaTarifas;
      }
    }));
  }

  public getButacasInfoMultiple = (butacas: string) => {
    const body : ReqButacaInfo = {
      Idioma: this.translate.getLocale(),
      RecintosButacasId: butacas
    }
    return this.http.post<ResButacasInfo>(`${this.getAPIUrl()}Butacainfo`, body).pipe(
      map((res: ResButacasInfo) => {
        return res.DatosResult;
      })
    );
  }

  public marcarDesmarcarButacasMultiple = (marcadas: Marcado[], marcar: string) => {
    const body: ReqMarcarDesmarcar = {
      SesionInternetId: this.configurationService.configurationValue.currentSession.sessionId,
      Taquilla: "0",
      Marcado: marcadas,
      Marcar: marcar
    }
    return this.http.post<ResMarcarDesmarcar>(`${this.getAPIUrl()}MarcarDesmarcarButaca`, body).pipe(
      map((res: ResMarcarDesmarcar) => {
        return res.DatosResult;
      })
    );
  } 
   
}
