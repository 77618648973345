import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { UserService } from "src/app/user/user.service";
import { Router } from "@angular/router";
import { TranslateService } from "src/app/shared/translate.service";
import { AppConfiguration } from "src/app/app.configuration";
import { ProductosService } from "src/app/business/productos.service";
import { InitService } from "src/app/core/init/init.service";
import { VentaService } from "src/app/core/services/venta.service";
import { TokenInit } from "src/app/models/token-init.model";
import { ModalService } from "src/app/shared/modal.service";
import { take, takeUntil, takeWhile } from "rxjs/operators";
import { Subject, Subscription } from "rxjs";
import { ConfigurationService } from "src/app/core/config/configuration.service";
import { DialogoPopUp } from "src/app/shared/components/alert/dialog/popup.service";
import { MatDialog } from "@angular/material/dialog";
import { ButacasService } from "src/app/business/espectaculos-butacas/mapa/servicios/butacas.service";
import { Carrito } from "src/app/models/carrito.model";
import { TimerService } from "src/app/core/services/timer.service";
import * as moment from 'moment';

@Component({
  selector: "app-barra-user",
  templateUrl: "./barra-user.component.html",
  styleUrls: ["./barra-user.component.scss"],
})
export class BarraUserComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  isLogged;
  nombreCliente;
  cargado;
  user;
  proyecto = this.config.getProyecto();
  lang = this.translate.getLocale();
  idiomas;
  ilogueo = -1;
  userAdmin = false;
  verAreaCliente = 0;
  configurationSub: Subscription;
  currentUserSub: Subscription;
  idiomasSub: Subscription;
  enlaceMenuCarritoSub: Subscription;
  fromAdmon: Subscription;
  fromAdm = false;
  public translation: any;

  constructor(
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
    private config: AppConfiguration,
    private productosService: ProductosService,
    private initService: InitService,
    private ventaService: VentaService,
    private modalService: ModalService,
    private configurationService: ConfigurationService,
    private _cdr: ChangeDetectorRef,
    private translator: TranslateService,
    public dialog: MatDialog,
    private butacasService: ButacasService,
    private timerService: TimerService
  ) {
    this.idiomasSub = this.initService.idiomas$.subscribe((idiomas) => {
      if (idiomas) {
        this.idiomas = idiomas;
        this.idiomas.forEach((element) => {
          element.CodIdiomaTrad = this.translator.data[element.CodIdioma];
        });
      }
    });
  }

  ngOnInit() {
    this.configurationSub = this.configurationService.configuration$.subscribe(
      (config) => {
        if (
          config &&
          config != undefined &&
          config != null &&
          config.datosProyecto
        ) {
          this.verAreaCliente =
            this.configurationService.getCaracteristicasAdicionalesProyecto()
              .MostrarAreaCliente || 0;
        }
      }
    );

    this.currentUserSub = this.userService.currentUser$.subscribe(
      (user) => (this.user = user)
    );
    
    this.idiomasSub = this.initService.idiomas$.subscribe((idiomas) => {
      if (idiomas) {
        this.idiomas = idiomas;
        this.idiomas.forEach((element) => {
          element.CodIdiomaTrad = this.translator.data[element.CodIdioma];
        });
      }
    });

    this.fromAdmon = this.initService.fromAdmon$.subscribe(
      (adm) => (this.fromAdm = adm)
    );

    this.initLoging();
    let lang = this.translate.getLocale();

    if (!localStorage.getItem("lastlang")) {
      localStorage.setItem("lastlang", lang);
    }
    if (localStorage.getItem("lastlang")) {
      this.changeLang(this.translate.getLocale());
      lang = localStorage.getItem("lastlang");
    }

    this.changeLang(lang);
    this.lang = lang;
    this.translate.setLocale(lang);
    this.translate.use(lang, this.proyecto);

    this.userService.logged.subscribe((lg) => {
      //window.location.reload();
      this.verAreaCliente =
        this.configurationService.getCaracteristicasAdicionalesProyecto().MostrarAreaCliente;
      //this._cdr.detectChanges();
    });

    this.userService.setlang.subscribe((l) => {
      this.translate.setLocale(l);
      this.translate.use(l, this.proyecto);
      this.lang = l;
      let _that = this;
      setTimeout(function () {
        _that._cdr.detectChanges();
      }, 4000);
    });
  }

  ngOnDestroy(): void {
    if (this.enlaceMenuCarritoSub) {
      this.enlaceMenuCarritoSub.unsubscribe();
    }
    if (this.idiomasSub) {
      this.idiomasSub.unsubscribe();
    }
    if (this.currentUserSub) {
      this.currentUserSub.unsubscribe();
    }
    if (this.configurationSub) {
      this.configurationSub.unsubscribe();
    }
  }

  initLoging() {
    this.enlaceMenuCarritoSub = this.ventaService.enlaceMenuCarrito$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((em) => {
        if (em) {
          const pkid = em.pkid ? em.pkid : em;
          const enlaceMenu = this.productosService.enlacesMenuValue.find(
            (e) => e.pkid === pkid
          );
          this.ilogueo = enlaceMenu.iLogueo;
        }
      });
  }
  navigateTop(route) {
    if (this.ventaService.modificandoReservaValue) {
      const dialogRef = this.dialog.open(DialogoPopUp, {
        width: "250px",
        data: {
          titulo: this.translator.data["TERMINAR_MODIFICACION"],
          texto: this.translator.data["Confirmar_terminar_modificacion"],
          eleccion: "Patatas",
          ok: this.translator.data["CONFIRMAR"],
          ko: this.translator.data["CANCELAR"],
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === "SI") {
          this.butacasService
          .DesmarcarTodoCarrito()
          .pipe(take(1))
          .subscribe((res) => {
            this.butacasService.MarcarTodoCarrito().pipe(take(1)).subscribe();
          });
          // this.ventaService.setFechaSeleccionadaValue(moment());
          this.timerService.reset();
          this.router.navigate([route]);
        }
      });
    } else {
      this.router.navigate([route]);
    }
  }

  checkUserAdmin() {
    this.userService.userAdmin$.subscribe((res) => {
      this.userAdmin = res;
    });
  }

  loginClick() {
    /* this.router.navigate(['/account/login']); */
    this.modalService.open("LoginAndRegister");
  }
  registroClick() {
    this.router.navigate(["/account/signup"]);
  }
  misDatosClick() {
    this.router.navigate(["/account/my"]);
  }
  misComprasClick() {
    this.router.navigate(["/account/purchases"]);
  }
  salirClick() {
    sessionStorage.removeItem("token");
    this.productosService.setDatosInitValue(undefined);
    this.userService.cerrarSesion().subscribe((res) => {});
    this.userService.logout();
  }

  changeLang(lang) {
    lang =
      lang == "ca" || lang == "es-ES"
        ? "es-ES"
        : lang == "fr-FR"
        ? "fr-FR"
        : "en-US";
    let b = document.getElementsByTagName("html");
    if (b && b.length > 0) {
      b[0].lang = lang;
    }
  }
  selectLangChange(lang) {
    if (lang) {
      this.setLang(lang);
    }
  }
  setLang(lang: string) {
    this.changeLang(lang);
    localStorage.setItem("lastlang", lang);
    this.translate.setLocale(lang);
    this.translate.use(lang, this.proyecto);
    this.lang = lang;
    this.userService.setlang.emit(lang);
    this._cdr.detectChanges();
    lang = this.translate.getLocale();
    //this.openModalCookies()
    if (this.productosService.datosInitValue) {
      let token: TokenInit;
      if (!JSON.parse(this.productosService.datosInitValue).gi) {
        const gi =
          this.ventaService.carritoValue.compras[0].producto.GrupoInternet;
        const datosUrl = JSON.parse(this.productosService.datosInitValue);
        token = { ...datosUrl, lg: lang, gi };
      } else {
        const datosUrl = JSON.parse(this.productosService.datosInitValue);
        token = { ...datosUrl, lg: lang };
      }
      sessionStorage.setItem("token", this.initService.funcionEncript(token));
      this.productosService.setDatosInitValue(token);
    }
    this.router.navigateByUrl("/reservarEntradas");
  }
  openModalCookies() {
    sessionStorage.removeItem("aceptaCookies");
    sessionStorage.removeItem("cookies");
    this.initService.modalCookies$
      .pipe(takeWhile(() => !this.cargado))
      .subscribe((cargado) => {
        this.cargado = cargado;
        if (this.cargado) {
          this.initService.setModalCookiesValue(false);
          this.initService.setAceptaCookieGoolge(false);
          this.modalService.open("cookies");
          this.cargado = false;
        }
      });
  }

  idiomaSelect(idioma) {
    if (this.translate.getLocale() === idioma) {
      return true;
    }
  }

  isZaragozaClient() {
    return [
      'AYTO_ZGZ_FORO', 
      'AYTO_ZGZ_TEATRO', 
      'AYTO_ZGZ_PUERTO',
      'AYTO_ZGZ_TERMAS',
      'AYTO_ZGZ_GARG'
    ].indexOf(this.proyecto) > -1;

  }
}
